import { ref, onMounted } from "vue";

export function useReducedMotion() {
  const prefersReducedMotion = ref(false);

  onMounted(() => {
    prefersReducedMotion.value = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    mediaQuery.addEventListener("change", (e) => {
      prefersReducedMotion.value = e.matches;
    });
  });

  return {
    prefersReducedMotion,
  };
}
